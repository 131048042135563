//
// SingleProjectInfo.js
//

import { useGetProjectDatabases } from "@custom-hooks/Databases";
import { useGetUserProjects } from "@custom-hooks/Projects";
import { useGetProjectApiKey, useGetProjectInfo } from "@custom-hooks/useApi";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";

import Card from "@generic-components/Card";
import CopyToClipboardButton from "@generic-components/CopyToClipboardButton";
import ModalContent from "@generic-components/ModalContent";

const useBuildConnectionString = (projectId) => {
  const { data: databases, showLoader: showLoaderDatabase } =
    useGetProjectDatabases(projectId);
  const { connectionStringApiKey, showLoader: showLoaderProjectInfo } =
    useGetProjectInfo(projectId);
  const { projectApiKey, showLoader: showLoaderApiKey } =
    useGetProjectApiKey(projectId);

  const databaseOptions = React.useMemo(() => {
    return (
      databases?.map((database) => ({
        label: database.name,
        value: database.name,
      })) ?? []
    );
  }, [databases]);

  const apiKeyOptions = React.useMemo(() => {
    return (
      projectApiKey?.map((apiKey) => ({
        label: apiKey.name,
        value: apiKey.key,
      })) ?? []
    );
  }, [projectApiKey]);
  const [selectedApiKey, setSelectedApiKey] = React.useState("");
  const [selectedDatabase, setSelectedDatabase] = React.useState("");

  React.useEffect(() => {
    if (databaseOptions?.length && apiKeyOptions?.length) {
      setSelectedDatabase(databaseOptions[0]?.value);
      setSelectedApiKey(apiKeyOptions[0]?.value);
    }
  }, [databaseOptions, apiKeyOptions]);

  const [connStr] = connectionStringApiKey?.split("?") ?? [];

  const connectionString = `${connStr}/${selectedDatabase}?apikey=${selectedApiKey}`;

  return {
    showLoader: showLoaderApiKey || showLoaderDatabase || showLoaderProjectInfo,
    connectionString,
    databaseOptions,
    selectedDatabase,
    setSelectedDatabase,
    setSelectedApiKey,
    apiKeyOptions,
    selectedApiKey,
  };
};

const SingleProjectInfo = ({ projectId }) => {
  const {
    data: projects,
    showLoader: showLoaderProjects,
    hasData: availableProjects,
  } = useGetUserProjects();

  // Extract projects with at least one node
  const filteredAndSortedProjects = availableProjects
    ? projects
        .filter((project) => project.nodes_count > 0) // Filter out projects with zero nodes
        .sort((a, b) => a.name.localeCompare(b.name)) // Sort projects alphabetically by name
    : [];

  const projectsOptions = React.useMemo(() => {
    return (
      filteredAndSortedProjects?.map((project) => ({
        label: project.name,
        value: project.id,
      })) ?? []
    );
  }, [projects]);

  const [selectedProject, setSelectedProject] = React.useState("");
  React.useEffect(() => {
    if (projectsOptions?.length) {
      setSelectedProject(projectsOptions[0]?.value);
    }
  }, [projectsOptions]);
  const {
    showLoader,
    connectionString,
    databaseOptions,
    selectedDatabase,
    selectedApiKey,
    setSelectedApiKey,
    apiKeyOptions,
    setSelectedDatabase,
  } = useBuildConnectionString(projectId ? projectId : selectedProject);
  return (
    <ModalContent
      title={"Connection String"}
      showLoader={showLoader || showLoaderProjects}
    >
      {!projectId && availableProjects && !showLoaderProjects && (
        <Stack gap="20px">
          <TextField
            select={true}
            value={selectedProject}
            label={"Project"}
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            {projectsOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      )}
      {selectedProject && !showLoader && connectionString && (
        <>
          <Stack gap="20px">
            <TextField
              select={true}
              value={selectedDatabase}
              label={"Database"}
              onChange={(e) => setSelectedDatabase(e.target.value)}
            >
              {databaseOptions.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select={true}
              value={selectedApiKey}
              label={"API Key"}
              onChange={(e) => setSelectedApiKey(e.target.value)}
            >
              {apiKeyOptions.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Card customSx={{ padding: "0.5rem 1rem" }}>
            <Box sx={{ mt: 0 }}>
              <CopyToClipboardButton
                label={connectionString ? connectionString : "..."}
                value={connectionString ? connectionString : "..."}
                alignLeft={true}
              />
            </Box>
          </Card>
        </>
      )}
    </ModalContent>
  );
};

export default SingleProjectInfo;
